// extracted by mini-css-extract-plugin
export default {"colorBlack":"#1d2126","colorBlackTransparent":"#1d212680","colorBlue":"#378ccb","colorBlueDark":"#0c65a7","colorBlueLighter":"#ebf4fa","colorGrayDark":"#5b656d","colorGrayDarker":"#313d48","colorGrayDarkest":"#24292f","colorGrayLight":"#adb2b6","colorGrayLighter":"#d6d8da","colorGrayLightest":"#ebeced","colorGreen":"#26b899","colorGreenLightest":"#e9f8f5","colorRed":"#e76f67","colorRedLightest":"#fdf1f0","colorText":"#24292f","colorWhite":"#fff","colorYellow":"#f6b551","fontWeightThin":"200","fontWeightRegular":"400","fontWeightSemiBold":"600","fontWeightBold":"700","fontSize10":".625rem","zIndexNeg1":"-1","zIndex0":"0","zIndex1":"1","zIndex2":"2","zIndex3":"3","zIndex4":"4","zIndex5":"5","container":"container_b1c1258","wrapper":"wrapper_d03958c","wrapperInner":"wrapperInner_fb6668b","backButtonContainer":"backButtonContainer_d439178","backButtonLink":"backButtonLink_b60c9c7","sprite":"sprite_ff98f7e","header":"header_dae533a","block":"block_defcb00","section":"section_b325357","sectionTitle":"sectionTitle_e355840","labelTooltip":"labelTooltip_d3f02c9","tooltipGlyph":"tooltipGlyph_e33df17","sectionMultiLine":"sectionMultiLine_d351fb2","sectionChildren":"sectionChildren_fa2170f","title":"title_b5bb2cb","contentWrapper":"contentWrapper_db8615c","content":"content_de202ff","icon":"icon_c892bad","spriteImg":"spriteImg_b46d283","img":"img_d91dab2","contentText":"contentText_e6b74d1","contentTitle":"contentTitle_a4a1c78","contentDetails":"contentDetails_e416f3c","submitFooter":"submitFooter_ae56251","buttonContainer":"buttonContainer_bfa5168","button":"button_c0716af","spinner":"spinner_ca31c12","spinnerStyle":"spinnerStyle_c03729f"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"colorBlack\":\"#1d2126\",\"colorBlackTransparent\":\"#1d212680\",\"colorBlue\":\"#378ccb\",\"colorBlueDark\":\"#0c65a7\",\"colorBlueLighter\":\"#ebf4fa\",\"colorGrayDark\":\"#5b656d\",\"colorGrayDarker\":\"#313d48\",\"colorGrayDarkest\":\"#24292f\",\"colorGrayLight\":\"#adb2b6\",\"colorGrayLighter\":\"#d6d8da\",\"colorGrayLightest\":\"#ebeced\",\"colorGreen\":\"#26b899\",\"colorGreenLightest\":\"#e9f8f5\",\"colorRed\":\"#e76f67\",\"colorRedLightest\":\"#fdf1f0\",\"colorText\":\"#24292f\",\"colorWhite\":\"#fff\",\"colorYellow\":\"#f6b551\",\"fontWeightThin\":\"200\",\"fontWeightRegular\":\"400\",\"fontWeightSemiBold\":\"600\",\"fontWeightBold\":\"700\",\"fontSize10\":\".625rem\",\"zIndexNeg1\":\"-1\",\"zIndex0\":\"0\",\"zIndex1\":\"1\",\"zIndex2\":\"2\",\"zIndex3\":\"3\",\"zIndex4\":\"4\",\"zIndex5\":\"5\",\"container\":\"container_b1c1258\",\"wrapper\":\"wrapper_d03958c\",\"wrapperInner\":\"wrapperInner_fb6668b\",\"backButtonContainer\":\"backButtonContainer_d439178\",\"backButtonLink\":\"backButtonLink_b60c9c7\",\"sprite\":\"sprite_ff98f7e\",\"header\":\"header_dae533a\",\"block\":\"block_defcb00\",\"section\":\"section_b325357\",\"sectionTitle\":\"sectionTitle_e355840\",\"labelTooltip\":\"labelTooltip_d3f02c9\",\"tooltipGlyph\":\"tooltipGlyph_e33df17\",\"sectionMultiLine\":\"sectionMultiLine_d351fb2\",\"sectionChildren\":\"sectionChildren_fa2170f\",\"title\":\"title_b5bb2cb\",\"contentWrapper\":\"contentWrapper_db8615c\",\"content\":\"content_de202ff\",\"icon\":\"icon_c892bad\",\"spriteImg\":\"spriteImg_b46d283\",\"img\":\"img_d91dab2\",\"contentText\":\"contentText_e6b74d1\",\"contentTitle\":\"contentTitle_a4a1c78\",\"contentDetails\":\"contentDetails_e416f3c\",\"submitFooter\":\"submitFooter_ae56251\",\"buttonContainer\":\"buttonContainer_bfa5168\",\"button\":\"button_c0716af\",\"spinner\":\"spinner_ca31c12\",\"spinnerStyle\":\"spinnerStyle_c03729f\"}";
        // 1728709537995
        var cssReload = require("../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  
// extracted by mini-css-extract-plugin
export default {"colorBlack":"#1d2126","colorBlackTransparent":"#1d212680","colorBlue":"#378ccb","colorBlueDark":"#0c65a7","colorBlueLighter":"#ebf4fa","colorGrayDark":"#5b656d","colorGrayDarker":"#313d48","colorGrayDarkest":"#24292f","colorGrayLight":"#adb2b6","colorGrayLighter":"#d6d8da","colorGrayLightest":"#ebeced","colorGreen":"#26b899","colorGreenLightest":"#e9f8f5","colorRed":"#e76f67","colorRedLightest":"#fdf1f0","colorText":"#24292f","colorWhite":"#fff","colorYellow":"#f6b551","fontWeightThin":"200","fontWeightRegular":"400","fontWeightSemiBold":"600","fontWeightBold":"700","fontSize10":".625rem","zIndexNeg1":"-1","zIndex0":"0","zIndex1":"1","zIndex2":"2","zIndex3":"3","zIndex4":"4","zIndex5":"5","selectLocation":"selectLocation_cd1b795","headerText":"headerText_bae676b","reserveNotification":"reserveNotification_efecec4","inputContainer":"inputContainer_f3e974a","dollarSign":"dollarSign_f92943a","startingPriceContainer":"startingPriceContainer_a9aac34","dropdown":"dropdown_a26a58f","placeholderDropdown":"placeholderDropdown_ce8c251","subTitle":"subTitle_db93673","radioButtons":"radioButtons_cbcb622","radioButtonsContainer":"radioButtonsContainer_fa4a828","radioButtonsAuction":"radioButtonsAuction_e39fd97","fetchingAuctionTimeSlotSpinner":"fetchingAuctionTimeSlotSpinner_e48f1c0","footer":"footer_bf8f1eb","button":"button_bf591f8","arrowSprite":"arrowSprite_c6564c0","spinnerStyle":"spinnerStyle_f773388","asIsContainer":"asIsContainer_a5880a2","asIsCause":"asIsCause_a37a464","asIsRadioLabel":"asIsRadioLabel_dbab347","container":"container_dc721af"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"colorBlack\":\"#1d2126\",\"colorBlackTransparent\":\"#1d212680\",\"colorBlue\":\"#378ccb\",\"colorBlueDark\":\"#0c65a7\",\"colorBlueLighter\":\"#ebf4fa\",\"colorGrayDark\":\"#5b656d\",\"colorGrayDarker\":\"#313d48\",\"colorGrayDarkest\":\"#24292f\",\"colorGrayLight\":\"#adb2b6\",\"colorGrayLighter\":\"#d6d8da\",\"colorGrayLightest\":\"#ebeced\",\"colorGreen\":\"#26b899\",\"colorGreenLightest\":\"#e9f8f5\",\"colorRed\":\"#e76f67\",\"colorRedLightest\":\"#fdf1f0\",\"colorText\":\"#24292f\",\"colorWhite\":\"#fff\",\"colorYellow\":\"#f6b551\",\"fontWeightThin\":\"200\",\"fontWeightRegular\":\"400\",\"fontWeightSemiBold\":\"600\",\"fontWeightBold\":\"700\",\"fontSize10\":\".625rem\",\"zIndexNeg1\":\"-1\",\"zIndex0\":\"0\",\"zIndex1\":\"1\",\"zIndex2\":\"2\",\"zIndex3\":\"3\",\"zIndex4\":\"4\",\"zIndex5\":\"5\",\"selectLocation\":\"selectLocation_cd1b795\",\"headerText\":\"headerText_bae676b\",\"reserveNotification\":\"reserveNotification_efecec4\",\"inputContainer\":\"inputContainer_f3e974a\",\"dollarSign\":\"dollarSign_f92943a\",\"startingPriceContainer\":\"startingPriceContainer_a9aac34\",\"dropdown\":\"dropdown_a26a58f\",\"placeholderDropdown\":\"placeholderDropdown_ce8c251\",\"subTitle\":\"subTitle_db93673\",\"radioButtons\":\"radioButtons_cbcb622\",\"radioButtonsContainer\":\"radioButtonsContainer_fa4a828\",\"radioButtonsAuction\":\"radioButtonsAuction_e39fd97\",\"fetchingAuctionTimeSlotSpinner\":\"fetchingAuctionTimeSlotSpinner_e48f1c0\",\"footer\":\"footer_bf8f1eb\",\"button\":\"button_bf591f8\",\"arrowSprite\":\"arrowSprite_c6564c0\",\"spinnerStyle\":\"spinnerStyle_f773388\",\"asIsContainer\":\"asIsContainer_a5880a2\",\"asIsCause\":\"asIsCause_a37a464\",\"asIsRadioLabel\":\"asIsRadioLabel_dbab347\",\"container\":\"container_dc721af\"}";
        // 1728709559596
        var cssReload = require("../../../../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  